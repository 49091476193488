<template>
    <div class="overlay">
        <div class="overlay-header">
            <slot name="header"></slot>
            <span class="close" @click="closeOverlay">X</span>
        </div>

        <div class="overlay-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
      closeOverlay() {
          this.$emit('closeOverlay');
      }
      
  }
};
</script>

<style scoped>
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
}
.overlay-header {
    padding: 25px;
    display: flex;
    justify-content: space-between;
}
.close {
    padding: 25px;
    font-weight: 600;
    cursor: pointer;
}

.overlay-header {
    background-color: #ffffff;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 50px;

    box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.15);
}
</style>