<template>
  <div id="app">
    <div class="gender-selector col-12">
      <label for="selector-male">
        <input type="radio" name="gender" class="selector-male" id="selector-male" value="0" v-model="gender" @change="genderChange" />
        <svg viewBox="0 0 24 24">
          <path d="M9,9C10.29,9 11.5,9.41 12.47,10.11L17.58,5H13V3H21V11H19V6.41L13.89,11.5C14.59,12.5 15,13.7 15,15A6,6 0 0,1 9,21A6,6 0 0,1 3,15A6,6 0 0,1 9,9M9,11A4,4 0 0,0 5,15A4,4 0 0,0 9,19A4,4 0 0,0 13,15A4,4 0 0,0 9,11Z" />
        </svg>
      </label>
      <label for="selector-female">
        <input type="radio" name="gender" class="selector-female" id="selector-female" value="1" v-model="gender" @change="genderChange" />
        <svg viewBox="0 0 24 24">
          <path d="M12,4A6,6 0 0,1 18,10C18,12.97 15.84,15.44 13,15.92V18H15V20H13V22H11V20H9V18H11V15.92C8.16,15.44 6,12.97 6,10A6,6 0 0,1 12,4M12,6A4,4 0 0,0 8,10A4,4 0 0,0 12,14A4,4 0 0,0 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </label>
    </div>
    <div class="count" col-12>
      <span v-text="''+ genderLeftCount + ' af ' + genderTotalCount + ' eftir ('+Math.round(genderLeftCount/genderTotalCount*100)+'%)'"></span>
    </div>
		<CardsStack
			:cards="activeItems"
      :gender="gender"
			@cardAccepted="handleCardAccepted"
			@cardRejected="handleCardRejected"
			@cardSkipped="handleCardSkipped"
			@hideCard="removeCardFromDeck"
		/>
    <div class="bottom-icons col-12">
      <div class="icon-bar" @click="openSettings">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
        </svg>
      </div>
    </div>
    <div v-if="showSettings">
      <overlay @closeOverlay="closeOverlay">
        <template v-slot:header>
          <h3>Stillingar</h3>
        </template>

        <template v-slot:body>
          <div>
            <ul>
              <li v-for="item in acceptedForGender" v-text="item.n" :key="item.id"></li>
            </ul>
          </div>
        </template>
      </overlay>
    </div>
    <div v-if="showCompair">
      <overlay @closeOverlay="closeOverlay">
        <template v-slot:header>
          <h3>Bera saman</h3>
        </template>
      </overlay>
    </div>
  </div>
</template>

<script>
import CardsStack from "./components/CardsStack";
import Overlay from "./components/Overlay";
import Json from '@/names.json';

export default {
  name: "App",
  components: {
    CardsStack, 
    Overlay
  },

  data() {
    return {
      visibleCards: ["Test", "Vue.js", "Webpack"],
      data: Json.data,
      activeItems: [],
      allNames: [],
      gender: 0,
      count: 0,
      showSettings: false,
      showCompair: false,
      shareSettings: null,
      genderLeftCount: 0,
      genderTotalCount: 0,
      acceptedForGender: []
    };
  },
  computed: {
    
  },

  methods: {
    handleCardAccepted() {
      this.handleItem(1);
    },
    handleCardRejected() {
      this.handleItem(0);
    },
    handleCardSkipped() {
      this.handleItem(null);
    },
    handleItem(value) {
      if(value !== null) {
        this.activeItems[0]['a'] = value;
      }
      this.activeItems.splice(0,1);
      this.activeItems.push(this.getUndecidedItemFromNames());
      //console.log(JSON.parse(JSON.stringify(this.getAllAcceptedNames())));
      this.saveListToStorage(this.allNames);
    },
    removeCardFromDeck() {
      this.visibleCards.shift();
    },
    activeListiInit() {
      this.activeItems = [];
      // Want to pull up 10 names from localList at start
      for(let i=0;i<10;i++) {
          this.activeItems.push(this.getUndecidedItemFromNames());
      }
    },
    getUndecidedItemFromNames() {
      let namesLeft = [];
      let totalGenderCount = 0;

      this.allNames.forEach(x => {
          if(Object.prototype.hasOwnProperty.call(x, 'g') && x['g'] == this.gender) {
            if(!Object.prototype.hasOwnProperty.call(x, 'a')) {
              namesLeft.push(x);
            }
            totalGenderCount++;
          }
      });

      let item = null;
      if(namesLeft.length > 0) {
        item = namesLeft[Math.floor(Math.random()*namesLeft.length)];
      }

      this.genderTotalCount = totalGenderCount;
      this.genderLeftCount = namesLeft.length;

      // Get random item from list
      //let item = this.allNames[Math.floor(Math.random()*this.allNames.length)];
      //let item = this.allNames[this.count];
      //this.count++;

      // Start by checking if item is of correct gender based on selection
      // if(Object.prototype.hasOwnProperty.call(item, 'g') && item['g'] != this.gender) {
      //     item = this.getUndecidedItemFromNames();
      // }

      // // if item has been accepted or denied we want to get new item
      // if(Object.prototype.hasOwnProperty.call(item, 'a')) {
      //     item = this.getUndecidedItemFromNames();
      // }
      // let alreadyAdded = false;
      // // We want to make sure that this item is not already in our output list
      // this.activeItems.forEach(x => {
      //     if(x.id == item.id) {
      //         alreadyAdded = true;
      //     }
      // });
      // // if it is already in output list we get new random
      // if(alreadyAdded) {
      //     item = this.getUndecidedItemFromNames();
      // }
      return item;
    },
    getAllAcceptedNames() {
      let result = [];
      this.allNames.forEach(x => {
          if(Object.prototype.hasOwnProperty.call(x, 'a') && x['a'] == 1) {
              result.push(x);
          }
      });
      return result;
    },
    getAllAcceptedNamesForGender() {
      let result = [];
      this.allNames.forEach((x) => {
        if(Object.prototype.hasOwnProperty.call(x, 'g') && x['g'] == this.gender) {
            if(Object.prototype.hasOwnProperty.call(x, 'a')) {
              result.push(x);
            }
          }
      });
      this.acceptedForGender = result;
    },
    shareAcceptedList() {

    },
    getListFromStorage() {
      const localListStorage = localStorage.getItem('local_name_list');
      this.allNames = localListStorage !== null && localListStorage.length > 0 ? JSON.parse(localListStorage) : null;
      if(this.allNames == null) {
        this.allNames = JSON.parse(JSON.stringify(this.data));
        this.saveListToStorage(this.allNames);
      }

    },
    saveListToStorage(data) {
      localStorage.setItem('local_name_list', JSON.stringify(data));
    },
    reset() {
      localStorage.removeItem('local_name_list');
    },
    genderChange() {
      this.activeListiInit();
    },
    openSettings() {
      this.getAllAcceptedNamesForGender();
      this.showSettings = true;
    },
    closeOverlay() {
      this.showSettings = false;
      this.showCompair = false;
    }
  },
  mounted() {
    this.getListFromStorage();
    this.activeListiInit();
    //this.reset();
  }
};
</script>

<style lang="scss">
@import "./styles/colors.scss";
@import "./styles/mixins.scss";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
}

.gender-selector,
.bottom-icons {
  width: 90%;
  position: fixed;
  left: 5%;
}

.count {
  width: 50%;
  margin: 0 auto;
  top: 80px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border: 1px solid lightgray;
}

.bottom-icons {
  bottom: 10px;
}

.icon-bar {
  padding: 5px;
  margin-left: 75px;
  margin-right: 75px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border: 1px solid lightgray;
}

#radios label {
  position: relative;
}

input[type="radio"] {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

input[type="radio"] + svg {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

label { 
  cursor: pointer;
}

input + svg {
  height: 70px;
  width: 70px;
}

label:hover > input[class="selector-male"] + svg,
label > input[class="selector-male"]:checked + svg,
label > input[class="selector-male"]:focus + svg {
  fill: $primary-blue !important;
}
label:hover > input[class="selector-female"] + svg,
label > input[class="selector-female"]:checked + svg,
label > input[class="selector-female"]:focus + svg {
  fill: $primary-pink !important;
}
</style>
