<template>
  <div class="cards col-12">
    <Card
      v-for="(card, index) in cards"
      :key="card.id"
      :card="card"
      :gender="gender"
      :is-current="index === 0"
      @cardAccepted="$emit('cardAccepted');"
      @cardRejected="$emit('cardRejected');"
      @cardSkipped="$emit('cardSkipped');"
      @hideCard="$emit('hideCard');"
    />
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: {
    Card
  },

  props: ['cards','gender']
};
</script>

<style lang="scss" scoped>
.cards {
  position: fixed;
  display: flex;
  margin: 3%;
  margin-top: 100px;
  width: 90%;
}
</style>
